import React, {Component} from "react"

class Landing9 extends Component{

    render(){

      return (
         <div className="landing9">
             landing9
         </div>
      )
    }
}

export default Landing9;
