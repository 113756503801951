import React,{Component} from "react"
import Layout from "./components/layout/layout"
import './App.scss';

class App extends Component{

  render(){

    return (
      <div className="App">
          <Layout/>
      </div>
    );
  }
}

export default App;
